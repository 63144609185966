import { Container } from "@material-ui/core";
import { graphql, Link } from "gatsby";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { CDButton } from "../../components/Shared/Button/CDButton";
import { ContentRenderer } from "../../components/Shared/ContentRenderer/ContentRenderer";
import { EmployeeContacts } from "../../components/Shared/EmployeeContacts/EmployeeContacts";
import { Heading } from "../../components/Shared/Heading/Heading";
import Layout from "../../components/Shared/Layout/Layout";
import { SEO } from "../../components/Shared/SEO/SEO";
import { muiTheme } from "../../components/Shared/Theme/Theme";
import { routes } from "../../lib/routes";

export const query = graphql`
  query($slug: String) {
    strapiFaq(slug: { eq: $slug }) {
      slug
      question
      shortAnswer
      longAnswer
    }
  }
`;

const Header = styled.header`
  margin-top: 16rem;

  ${muiTheme.breakpoints.down("sm")} {
    margin-top: 8rem;
  }
`;

const employeeContactStyles = css`
  margin-top: 12rem;
  ${muiTheme.breakpoints.down("sm")} {
    margin-top: 6rem;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10rem;

  ${muiTheme.breakpoints.down("sm")} {
    margin-top: 6rem;
  }
`;

const IndexPage = ({ data: { strapiFaq } }: any) => {
  const { question, shortAnswer, slug, longAnswer } = strapiFaq;
  return (
    <Layout>
      <SEO title={question} description={shortAnswer} />
      <Container>
        <Header>
          <Heading heading={question} pattern />
        </Header>
        <ContentRenderer source={longAnswer} />
        <EmployeeContacts className={employeeContactStyles} />
        <Actions>
          <Link to={routes.contact.to}>
            <CDButton>Kontakt</CDButton>
          </Link>
        </Actions>
      </Container>
    </Layout>
  );
};

export default IndexPage;
